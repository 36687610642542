import { SyntheticEvent, memo, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import { useRecoilValue } from 'recoil';

import { navMenuList } from './util';
import { BetaIconComponent, dropdownIcon, newIconDefault, sidebarToggleIcon } from 'assets/image';
import sweetAlert from 'prev-components/Alert/sweetAlert';

import { isLoggedInVar } from 'lib/apollo/apollo';
import usePageHistory from 'hooks/Common/usePageHistory';
import { showLogin } from 'store/Member';
import { isNavOpen } from 'store/navigationState';

import useGetDate from 'hooks/Common/useGetDate';
import {
  Que_Get_Gpt_Treemap_Data,
  Que_Get_Gpt_Treemap_DataVariables,
} from '__apollo__generated__/Que_Get_Gpt_Treemap_Data';
import { GET_GPT_TREEMAP_DATA } from 'graphql/Logistic/IssueMonitor';
import { GdeltMainCategory } from '__apollo__generated__/globalTypes';
import { userPlanSelector } from 'atom/user';

import { DateTime } from 'luxon';
// import { useCloseClickOutside } from 'hooks/Common/useCloseClickOutside';

interface SidebarProps {
  isopen?: string;
  isLoggedIn?: boolean;
  isDashboard?: boolean;
}

const Sidebar = styled.div<SidebarProps>`
  height: 100vh;
  position: fixed;
  align-self: auto;
  top: 90px;
  z-index: 50;
  min-width: ${({ isopen }) => (isopen ? '260px !important' : '60px !important')};
  max-width: ${({ isopen }) => (isopen ? '260px !important' : '60px !important')};
  background-color: ${({ theme }) => theme.dashboardColors.white};
  border-right: 1px solid #e2e2e2;
  transition-duration: 300ms;
  overflow-y: auto;
  -ms-overflow-style: none;
  transform-origin: left;
  ::-webkit-scrollbar {
    display: none; /*  Chrome , Safari , Opera */
  }
`;

const ToggleBtn = styled.img`
  cursor: pointer;
  padding: 18px 17px;
  background-color: #fff;
  transform: rotate(180deg);

  &.closed {
    transform: rotate(360deg);
  }
`;

const MenuList = styled.ul`
  margin-bottom: 95px;
`;

const MenuWrapper = styled.li<SidebarProps>`
  transition: background-color 200ms ease-in;

  &:hover {
    background-color: ${({ theme, isopen }) => (isopen ? theme.dashboardColors.white : '#D4FFDA')};
  }
`;

const Menu = styled.button`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 13px;
`;

const MenuLink = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 13px 13px;
  cursor: pointer;
`;

const MenuContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  div.box-menu-icon {
    width: 28px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MenuTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.21428571;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.dashboardColors.mainFont};
  white-space: nowrap;
  transform-origin: left;
  transition-duration: 200ms;

  &.hidden {
    display: none;
  }

  img.new-icon {
    width: 16px;
    margin-left: 10px;
  }
`;

const Line = styled.div`
  margin: 10px 0;
  border-bottom: 1px solid #f3f3f3;

  &.line-hidden {
    border-bottom: 1px solid transparent;
  }
`;

const DropdownIcon = styled.img`
  display: flex;
  justify-self: flex-end;
`;

const Submenu = styled.li`
  height: 50px;
  background-color: #f5f5f5;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.21428571;
  letter-spacing: -0.02em;
  color: #454545;
  transform-origin: left;
  transition-duration: 200ms;
  white-space: nowrap;

  &:hover {
    background-color: #eaeaea;
  }
`;

const SubmenuLink = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 62px;
`;

const PlanContainer = styled.div`
  padding: 0 16px;
  margin-bottom: 150px;
  word-break: keep-all;

  h4 {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.21428571;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.dashboardColors.black};
  }

  strong {
    display: block;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.21428571;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.dashboardColors.base};
  }

  p {
    margin-bottom: 13px;
    font-size: 12px;
    line-height: 1.16666667;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.dashboardColors.mainFont};
  }
`;

const PlanLink = styled(Link)`
  padding: 7px 10px;
  border: 1px solid ${({ theme }) => theme.dashboardColors.base};
  border-radius: 100px;
  font-size: 12px;
  line-height: 1.16666667;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.dashboardColors.base};
  transition: background-color 150ms ease-in;

  &:hover {
    background-color: ${({ theme }) => theme.dashboardColors.greenBackground};
  }
`;

const SubmenuContents = styled.div`
  position: relative;
`;

const NewIcon = styled.div`
  position: absolute;
  top: 1.8px;
  left: -20px;

  img {
    width: 16px;
  }
`;

const BetaIcon = styled(BetaIconComponent)<SidebarProps>`
  position: absolute;
  /* right: -20px;
  bottom: 20px; */
  width: 40px;
  height: auto;
  right: ${({ isopen }) => (isopen ? '-40px' : '-20px')};
  bottom: ${({ isopen }) => (isopen ? '20px' : '20px')};
`;

export default memo(function LogisticNavigationAdaptive() {
  const client = useApolloClient();

  const { formattingDate } = useGetDate();
  const { history, pathName } = usePageHistory();

  const [currentMenu, setCurrentMenu] = useState('');

  const isNavOpenVar = useReactiveVar(isNavOpen);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const userPlan = useRecoilValue(userPlanSelector);
  // const currentPort = useReactiveVar(selectedMarker);

  const ref = useRef<HTMLDivElement>(null);

  // useCloseClickOutside(ref, () => isNavOpen(false));

  useEffect(() => {
    isNavOpen(true);
  }, [isLoggedIn]);

  const handleNavClick = () => {
    if (isNavOpenVar === true) {
      isNavOpen(false);
    } else {
      isNavOpen(true);
    }
    setCurrentMenu('');
  };

  const handleMenuClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    const {
      currentTarget: { value },
    } = event;

    isNavOpen(true);
    setCurrentMenu(prev => {
      if (prev === value) {
        return '';
      }
      return value;
    });
  };

  return (
    <Sidebar
      ref={ref}
      isLoggedIn={isLoggedIn}
      isopen={isNavOpenVar ? isNavOpenVar.toString() : undefined}
      isDashboard={pathName === '/logistic-tools/main' ? true : false}
    >
      <ToggleBtn
        src={sidebarToggleIcon}
        alt="toggle-icon"
        onClick={handleNavClick}
        className={!isNavOpenVar ? 'closed' : ''}
      />
      <MenuList>
        {navMenuList.map((menu, index) => {
          const today = DateTime.now().toMillis();

          const newCategoryDate = menu?.newFeatureDate;

          const newCategory =
            newCategoryDate && DateTime.fromISO(newCategoryDate).plus({ day: 14 }).toMillis() >= today;

          const newFeatureDateList = menu.submenuList?.filter(item => item.newFeatureDate);

          const hasNewFeature =
            newFeatureDateList &&
            newFeatureDateList?.find(
              item =>
                item?.newFeatureDate && DateTime.fromISO(item.newFeatureDate).plus({ day: 14 }).toMillis() >= today,
            );

          return (
            <MenuWrapper key={`nav_${index}_${menu}`} isopen={isNavOpenVar ? isNavOpenVar.toString() : undefined}>
              {menu.url && !menu.submenuList && !menu.comingSoon ? (
                <MenuLink
                  onClick={() => {
                    if (!isLoggedIn) {
                      return showLogin(true);
                    }
                    if (menu.url) {
                      // history.push(menu?.url);
                      setTimeout(() => {
                        history.push(menu?.url!);
                      }, 100);
                    }
                  }}
                  onMouseEnter={async () => {
                    if (menu.url?.includes('issue')) {
                      if (isLoggedIn) {
                        await client.query<Que_Get_Gpt_Treemap_Data, Que_Get_Gpt_Treemap_DataVariables>({
                          query: GET_GPT_TREEMAP_DATA,

                          variables: {
                            input: {
                              mainCategory: GdeltMainCategory.ISSUE,
                              currentDate: formattingDate,
                            },
                          },
                        });
                      }
                    }
                  }}
                >
                  <MenuContent className="relative">
                    {menu?.isBeta && <BetaIcon isopen={isNavOpenVar ? isNavOpenVar.toString() : undefined} />}
                    <div className="box-menu-icon">
                      <img src={menu.icon} alt={`category_icon_${menu.title}`} />
                    </div>
                    <MenuTitle className={isNavOpenVar ? 'flex items-center' : 'hidden'}>
                      {menu.title}
                      {newCategory && <img src={newIconDefault} alt="new" className="new-icon" />}
                    </MenuTitle>
                  </MenuContent>
                </MenuLink>
              ) : menu.url && menu.comingSoon ? (
                <Menu
                  type="button"
                  onClick={() =>
                    sweetAlert({
                      icon: 'warning',
                      title: '서비스 준비중입니다.',
                      showDeny: false,
                    })
                  }
                >
                  <MenuContent>
                    <div className="box-menu-icon">
                      <img src={menu.icon} alt={`category_icon_${menu.title}`} />
                    </div>
                    <MenuTitle className={isNavOpenVar ? '' : 'hidden'}>{menu.title}</MenuTitle>
                  </MenuContent>
                </Menu>
              ) : (
                <Menu type="button" onClick={handleMenuClick} value={menu.title}>
                  <MenuContent>
                    <div className="box-menu-icon">
                      <img src={menu.icon} alt={`category_icon_${menu.title}`} />
                    </div>
                    <MenuTitle className={isNavOpenVar ? 'flex items-center' : 'hidden'}>
                      {menu.title}
                      {hasNewFeature && <img src={newIconDefault} alt="new" className="new-icon" />}
                    </MenuTitle>
                  </MenuContent>
                  {isNavOpenVar && menu.submenuList && currentMenu.includes(menu.title) && (
                    <DropdownIcon src={dropdownIcon} alt="drop-icon-nav" />
                  )}
                </Menu>
              )}
              {menu.gap && <Line className={isNavOpenVar ? '' : 'line-hidden'} />}
              {menu.title !== '홈' && menu.submenuList && currentMenu.includes(menu.title) && isNavOpenVar && (
                <ul>
                  {menu.submenuList.map((submenu, idx) => {
                    const newFeatureSubDate = submenu?.newFeatureDate;

                    const hasNewFeatureSub =
                      newFeatureSubDate && DateTime.fromISO(newFeatureSubDate).plus({ day: 14 }).toMillis() >= today;
                    return (
                      <Submenu key={`nav_sub_${idx}_${submenu}`}>
                        <SubmenuLink
                          onClick={() => {
                            if (isLoggedIn) {
                              // isNavOpen(false);
                              // history.push(submenu.url);
                              setTimeout(() => {
                                history.push(submenu.url);
                              }, 100);
                            } else {
                              return showLogin(true);
                            }
                          }}
                        >
                          <SubmenuContents>
                            {hasNewFeatureSub && (
                              <NewIcon>
                                <img src={newIconDefault} alt="new" />
                              </NewIcon>
                            )}
                            {submenu.title}
                          </SubmenuContents>
                        </SubmenuLink>
                      </Submenu>
                    );
                  })}
                </ul>
              )}
            </MenuWrapper>
          );
        })}
      </MenuList>

      {isNavOpenVar && (
        <PlanContainer>
          <h4>이용 중인 플랜</h4>
          <strong>{userPlan?.productAlias} 플랜</strong>
          <p>플랜을 업그레이드하고, 고도화된 데이터 분석을 통해서 위험을 사전에 인지하세요!</p>

          <PlanLink to="/plan/explain">플랜 보기</PlanLink>
        </PlanContainer>
      )}
    </Sidebar>
  );
});
