import { naverLogoColor, youtubeLogoColor } from 'assets/image';
import { Link } from 'react-router-dom';
import { footerMenu } from '../util';

import { getClass } from 'util/tailwind';

export default function WebFooter() {
  const handleOpenChat = () => {
    window.open(
      'https://larchive.simplogis.com/community/notice/638f036b4dfa2eaa84d7c720?page=1&hash=%EC%A0%84%EC%B2%B4',
    );
  };
  return (
    <footer className={getClass('text-[#464646]  px-[120px]  pt-[40px] pb-[20px] w-full  flex flex-col items-center')}>
      <div className="min-w-[1200px] max-w-[1600px] w-full ">
        {/* link */}
        <div className="flex items-center justify-between mb-3 break-words border-b-[1px] border-gray-300 border-solid pb-3">
          <div className="flex gap-4">
            {footerMenu.slice(0, 2).map(link => (
              <Link className="text-[#464646]" to={{ pathname: link['url'] }} key={link['name']}>
                {link['name']}
              </Link>
            ))}
          </div>
          <div className="flex gap-4">
            {footerMenu.slice(2).map(link => (
              <Link className="text-[#464646]" to={{ pathname: link['url'] }} key={link['name']}>
                {link['name']}
              </Link>
            ))}
            <button className="text-[#464646] text-[16px]" onClick={handleOpenChat}>
              오픈채팅
            </button>
          </div>
        </div>
        {/* inforamtion */}
        <div>
          <div className="py-3">
            <span>라카이브(주)</span>
            <span className='before:content-["|"] before:text-gray-300 before:mx-3 '>대표이사 오현민</span>
            <span className='before:content-["|"] before:text-gray-300 before:mx-3 '>사업자번호: 625-81-00962</span>
            <span className='before:content-["|"] before:text-gray-300 before:mx-3 '>
              통신판매신고: 제 2024-인천미추홀-0423호
            </span>
            <span className='before:content-["|"] before:text-gray-300 before:mx-3 '>이메일: help@simplogis.com</span>
            {/*<span className='before:content-["|"] before:text-gray-300 before:mx-3 '>전화번호: 032-710-7102</span>*/}
          </div>
        </div>
        <div className="pt-3 pb-0.5">본사: 인천시 연수구 컨벤시아대로 204, 인천스타트업파크 인스타1 516호</div>
        {/* <div className="pt-3 pb-0.5">본사: 인인천시 연수구 컨벤시아대로 204, 인천스타트업파크 인스타1 516호</div> */}
        <div className="py-3">서울영업소: 서울특별시 마포구 마포대로 34(도화동, 도원빌딩 9층, 3호실)</div>
        <div className="py-3">모든 데이터는 저작권법의 보호를 받은바, 무단전재, 복사-배포 등을 금합니다.</div>
        <div className="flex gap-3 py-3">
          <Link to={{ pathname: 'https://blog.naver.com/larchive_simplogis' }} target="_blank">
            <img src={naverLogoColor} alt="네이버" />
          </Link>
          <Link to={{ pathname: 'https://www.youtube.com/watch?v=WR6xizJGEkw' }} target="_blank">
            <img src={youtubeLogoColor} alt="유튜브" />
          </Link>
        </div>
        <div className="py-6">Copyright © simplogis. All Rights Reserved.</div>
      </div>
    </footer>
  );
}
