import { gql, useApolloClient, useMutation } from '@apollo/client';
import { deleteObject, ref } from 'firebase/storage';
import { SyntheticEvent, useEffect, useState } from 'react';
import { storage } from 'lib/firebase/firebase';

import useShowProfile from 'hooks/User/useShowProfile';
import fileUpload from 'lib/firebase/uploader/fileUpload';
import sweetToast from 'prev-components/Alert/sweetToast';

import { Mut_Edit_Profile_Photo, Mut_Edit_Profile_PhotoVariables } from '__apollo__generated__/Mut_Edit_Profile_Photo';
import { EDIT_PROFILE_PHOTO } from 'graphql/Mypage';

interface UserProfileInfo {
  id?: number | null;
  name?: string | null;
  avartar?: string | null;
}

function useMypageHeader() {
  const client = useApolloClient();
  const [userInfo, setUserInfo] = useState<UserProfileInfo | null>(null);

  const { data, loading } = useShowProfile();

  useEffect(() => {
    if (!loading && data) {
      setUserInfo({
        id: data?.showProfile?.user?.id,
        name: data?.showProfile?.user?.nickname,
        avartar: data?.showProfile?.user?.profilePhoto || null,
      });
    }
    return () => setUserInfo(null);
  }, [loading, data, setUserInfo]);

  // edit photo
  const [editPhoto, { loading: editPhotoLoading }] = useMutation<
    Mut_Edit_Profile_Photo,
    Mut_Edit_Profile_PhotoVariables
  >(EDIT_PROFILE_PHOTO, {
    onCompleted: (data: Mut_Edit_Profile_Photo) => {
      const {
        editProfilePhoto: { ok },
      } = data;
      if (!ok) {
        return;
      }

      sweetToast({
        icon: 'info',
        title: '프로필 사진이 변경되었습니다.',
      });
    },
  });

  const editProfilePhoto = async (event: SyntheticEvent<HTMLInputElement>) => {
    if (editPhotoLoading) return;
    const {
      currentTarget: { files },
    } = event;

    try {
      if (files) {
        const uploadFile = await fileUpload(files[0], userInfo?.id || 0);
        if (userInfo?.avartar) {
          deleteObject(ref(storage, userInfo?.avartar));
        }

        client.writeFragment({
          id: `User:${userInfo?.id}`,
          fragment: gql`
            fragment UserProfilePhoto on User {
              profilePhoto
            }
          `,
          data: {
            profilePhoto: uploadFile,
          },
        });
        await editPhoto({
          variables: {
            input: {
              profilePhoto: uploadFile,
            },
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    userInfo,
    editProfilePhoto,
  };
}

export default useMypageHeader;
