import { useReactiveVar } from '@apollo/client';
import usePageHistory from 'hooks/Common/usePageHistory';
import channelService from 'lib/channeltalk';
import { useEffect } from 'react';
import { showLogin } from 'store/Member';
import { userProfileVar } from 'store/UserStore';

export default function useWebMainLayout() {
  const showLoginVar = useReactiveVar(showLogin);
  const { pathName, history } = usePageHistory();
  const user = useReactiveVar(userProfileVar);

  useEffect(() => {
    const revalidate = () => {
      // Check if the tab is visible
      if (document.visibilityState === 'visible') {
        const date = Date.now();
        const result = sessionStorage.getItem('hidden-browser');
        if (!result || +date > +result + 1200000) {
          window.location.reload();
        }
      } else if (document.visibilityState === 'hidden') {
        const date = Date.now();
        sessionStorage.setItem('hidden-browser', JSON.stringify(date));
      }
    };

    document.addEventListener('visibilitychange', revalidate);
  }, []);

  useEffect(() => {
    channelService.boot({
      pluginKey: `${process.env.REACT_APP_CHANNEL_TALK_PLUGIN_KEY}`,
    });
  }, []);

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  const topClick = () => {
    document.documentElement?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return { showLoginVar, topClick, pathName, user, history };
}
