import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { retryLazy } from 'util/LazyUtil';
import { Suspense } from 'react';
// import ReactGA from 'react-ga4';

import MemeberAuthCallback from 'views/AuthCallback';
import HealthCheck from 'health/check';
import ResetPassword from 'views/ResetPassword';

// import WebMainLayout from 'prev-components/layouts/WebMainLayout';
import NotFoundPage from 'NotFound';
// import { LOGISTIC_ROUTE_LIST } from './LogisticRouterList';

import WebMainLayout from 'prev-components/layouts/WebMainLayout';
import MypageMainLayout from 'views/MyPage/MypageMainLayout';
import LogisticToolsContainerAdaptiveLayout from 'views/LogisticTools/Components/layouts';
// import { userProfileVar } from 'store/UserStore';

const FreightConditionMonitorV1 = retryLazy(() => import('views/FreightConditionMonitorV1'));
const RiskMonitor = retryLazy(() => import('views/RiskMonitorV1'));
const PortCongestionV3 = retryLazy(() => import('views/PortMonitoring'));

const GlobalSupplyChain = retryLazy(() => import('views/GlobalSupplyChain'));
const Home = retryLazy(() => import('../views/Home'));
const CompanyAd = retryLazy(() => import('../views/CompanyAd'));
const CompanyAdDetail = retryLazy(() => import('../views/CompanyAd/CompanyAdDetail'));

const Plan = retryLazy(() => import('views/Plan'));
const Faq = retryLazy(() => import('views/Faq'));

const Term = retryLazy(() => import('../views/Terms/Term'));
const Privacy = retryLazy(() => import('../views/Terms/Privacy'));
const Partnership = retryLazy(() => import('../views/Partnership'));
const Adinquiry = retryLazy(() => import('../views/Adinquiry'));

const Notice = retryLazy(() => import('../views/Community/Notice'));
const NewsCenter = retryLazy(() => import('../views/Community/NewsCenter'));
// const Fame = retryLazy(() => import('../views/Ranking/Fame'));
// const Knowledge = retryLazy(() => import('../views/Ranking/Knowledge'));

const ReferenceRoom = retryLazy(() => import('../views/Community/ReferenceRoom'));
// const Game = retryLazy(() => import('../views/Game'));

const CargoEstimates = retryLazy(() => import('views/CargoEstimates'));

// const MypageMainLayout = retryLazy(() => import('../views/MyPage/MypageMainLayout'));
const MypageMain = retryLazy(() => import('../views/MyPage'));
const MypageDefaultConfig = retryLazy(() => import('../views/MyPage/MypageDefaultConfig'));
// const MypageProfileConfig = retryLazy(() => import('../views/MyPage/MypageProfileConfig'));
const MypageCompanyAbout = retryLazy(() => import('../views/MyPage/MypageCompanyAbout'));
const MypageMessage = retryLazy(() => import('../views/MyPage/MypageMessage'));
// const AttendCheck = retryLazy(() => import('../views/MyPage/AttendCheck'));
const MypagePayment = retryLazy(() => import('../views/MyPage/MypagePayment'));
const MypageTeamPlan = retryLazy(() => import('../views/MyPage/MypageTeamPlan'));
const MypagePaymentCancel = retryLazy(() => import('../views/MyPage/MypagePayment/MypagePaymentCancel'));
const MypagePaymentRefund = retryLazy(() => import('../views/MyPage/MypagePayment/MypagePaymentRefund'));

const ContainerCharterRateIndex = retryLazy(() => import('views/LogisticTools/SeaIndex/ContainerCharterRateIndex'));
const ContainerFreightList = retryLazy(() => import('views/LogisticTools/SeaIndex/ContainerRateIndex'));
const ContainerSupplyIndex = retryLazy(() => import('views/LogisticTools/SeaIndex/ContainerSupplyIndex'));

const FCL = retryLazy(() => import('views/LogisticTools/Schedule/FCL'));
const ContainerTerminal = retryLazy(() => import('views/LogisticTools/Schedule/ContainerTerminal'));

const ShippingCapacity = retryLazy(() => import('views/LogisticTools/SeaIndex/ShippingCapacity'));

const TankerIndex = retryLazy(() => import('views/LogisticTools/SeaIndex/TankerIndex'));

const Bunkering = retryLazy(() => import('views/LogisticTools/SeaIndex/Bunkering'));

const ScheduleReliability = retryLazy(() => import('views/LogisticTools/SeaIndex/ScheduleReliability'));

const ShipPriceIndex = retryLazy(() => import('views/LogisticTools/SeaIndex/ShipPriceIndex'));

const GlobalOceanTeu = retryLazy(() => import('views/LogisticTools/SeaIndex/GlobalOceanTeu'));
const MUPV = retryLazy(() => import('views/LogisticTools/SeaIndex/Mupv'));

// * 블랭크 세일링
const BlankSailings = retryLazy(() => import('views/LogisticTools/SeaIndex/BlankSailings'));

// * congestion index
// const PortCongestionV2 = retryLazy(() => import('views/LogisticTools/CongestionLogistics/PortCongestionV2'));

// * 글로벌 초크포인트
const GlobalChokePointMonitor = retryLazy(
  () => import('views/LogisticTools/CongestionLogistics/GlobalChokePointMonitoring'),
);

// * 미주 철도 터미널 혼잡도
const UsaTerminalCongested = retryLazy(() => import('views/LogisticTools/CongestionLogistics/UsaTerminalCongested'));

// * 선박위치조회
const ShipTracking = retryLazy(() => import('views/LogisticTools/CongestionLogistics/ShipTracking'));
// * 세계기상정보
const WorldWeatherMap = retryLazy(() => import('views/LogisticTools/CongestionLogistics/WorldWeatherMap'));

// * air index
const AirRateIndex = retryLazy(() => import('views/LogisticTools/AirInlandIndex/AirRateIndex'));

const FSSC = retryLazy(() => import('views/LogisticTools/AirInlandIndex/Fssc'));

// * 미주 내륙 운송 혼잡도
const UsaTruckCongestion = retryLazy(() => import('views/LogisticTools/CongestionLogistics/UsaTruckCongestion'));

// * 항공유
const JFPM = retryLazy(() => import('views/LogisticTools/AirInlandIndex/Jfpm'));

//* IATAP
const IaTaP = retryLazy(() => import('views/LogisticTools/AirInlandIndex/IATAP'));

// * IATA
const IaTa = retryLazy(() => import('views/LogisticTools/AirInlandIndex/IATA'));

//* OAG
const OagGlobalCapacity = retryLazy(() => import('views/LogisticTools/AirInlandIndex/OagGlobalCapacity'));

const UsaTruckRateIndex = retryLazy(() => import('views/LogisticTools/AirInlandIndex/UsaTruckRateIndex'));

// * 미주 인터모달 운임지수 ISR
const UsaIntermodalSpotRate = retryLazy(() => import('views/LogisticTools/AirInlandIndex/UsaIntermodalSpotRate'));

// 미주 가솔린
const UsaGasolineDiesel = retryLazy(() => import('views/LogisticTools/AirInlandIndex/UsaGasolineDiesel'));
// * business support
const InlandTariffRate = retryLazy(() => import('views/LogisticTools/BusinessSupport/InlandTariffRate'));
const VehicleSpecMain = retryLazy(() => import('views/LogisticTools/BusinessSupport/VehicleSpec/VehicleSpecMain'));
const CustomExchangeRate = retryLazy(() => import('views/LogisticTools/BusinessSupport/CustomExchangeRate'));
const FacilltyList = retryLazy(() => import('views/LogisticTools/BusinessSupport/FacilityList'));
const ImportCargoClearance = retryLazy(() => import('views/LogisticTools/BusinessSupport/ImportCargoClearance'));
const NationalHoliday = retryLazy(() => import('views/LogisticTools/BusinessSupport/NationalHoliday'));
// const WordExchangeRate = retryLazy(() => import('views/LogisticTools/BusinessSupport/CountryExchangeRate'));

// * raw materials index
const OilIndex = retryLazy(() => import('views/LogisticTools/RawMaterialsIndex/OilIndex'));

// * 세계광물가격
const GlobalMineralPrices = retryLazy(() => import('views/LogisticTools/RawMaterialsIndex/GlobalMineralPrices'));

// * calculator
const DutyCalculator = retryLazy(() => import('views/LogisticTools/Calculator/DutyCalculator'));
const CbmCalculator = retryLazy(() => import('views/LogisticTools/Calculator/CbmCalculator'));

// * other index
const PmiIndex = retryLazy(() => import('views/LogisticTools/OtherIndex/PmiIndex'));
const LMI = retryLazy(() => import('views/LogisticTools/OtherIndex/LMI'));

// * issue monitor
// const IssueMonitoring = retryLazy(() => import('views/LogisticTools/IssueMonitoring/Monitoring'));
// const RiskDetect = retryLazy(() => import('views/LogisticTools/IssueMonitoring/RiskDetect'));

// * mydashboard
const MyDashboardV2 = retryLazy(() => import('views/LogisticTools/MyDashboardV2'));

// * 세계식량가격
const FaoOverview = retryLazy(() => import('views/LogisticTools/RawMaterialsIndex/GlobalFoodPrices/FaoOverview'));
const FaoPrice = retryLazy(() => import('views/LogisticTools/RawMaterialsIndex/GlobalFoodPrices/FaoPrice'));
const FaoGrain = retryLazy(() => import('views/LogisticTools/RawMaterialsIndex/GlobalFoodPrices/FaoGrain'));
const Gscpi = retryLazy(() => import('views/LogisticTools/OtherIndex/GSCPI'));
const Mtsr = retryLazy(() => import('views/LogisticTools/OtherIndex/MTSR'));
const BlackRock = retryLazy(() => import('views/LogisticTools/OtherIndex/BlackRock'));
const Gpr = retryLazy(() => import('views/LogisticTools/OtherIndex/FedGpr'));

const EuEua = retryLazy(() => import('views/LogisticTools/OtherIndex/EuEua'));

// * AAPA 아태 항공
const AAPA = retryLazy(() => import('views/LogisticTools/AirInlandIndex/AAPA'));

// const MyDashboardV1 = retryLazy(() => import('views/LogisticTools/MyDashboardV1'));
const LogisticDashboard = retryLazy(() => import('views/LogisticTools/LogisticDashBoard'));

// * bulk index
const BulkIndex = retryLazy(() => import('views/LogisticTools/SeaIndex/BulkIndex'));

const WestUsaTerminalCongestion = retryLazy(
  () => import('views/LogisticTools/CongestionLogistics/WestUsaTerminalCongestion'),
);

export const LOGISTIC_ROUTE_LIST = [
  { path: '/logistic-tools/main', component: LogisticDashboard, description: '메인 대시보드', exact: true },
  // { path: '/logistic-tools/issue-monitoring/risk-detect', component: RiskDetect, description: '이슈 모니터' },
  // { path: '/logistic-tools/my-dashboard', component: MyDashboardV1, description: '마이 대시보드' },
  { path: '/logistic-tools/my-dashboard', component: MyDashboardV2, description: '마이 대시보드' },
  { path: '/logistic-tools/schedule/sea/fcl', component: FCL },
  { path: '/logistic-tools/schedule/container-terminal', component: ContainerTerminal },
  // { path: '/logistic-tools/schedule/airline-schedule', component: AirRateIndex },
  { path: '/logistic-tools/sea-index/container-rate-index', component: ContainerFreightList },
  { path: '/logistic-tools/sea-index/container-charter-rate-index', component: ContainerCharterRateIndex },
  { path: '/logistic-tools/sea-index/container-supply-index', component: ContainerSupplyIndex },
  { path: '/logistic-tools/sea-index/bulk-index', component: BulkIndex },
  { path: '/logistic-tools/sea-index/tanker-index', component: TankerIndex },
  { path: '/logistic-tools/sea-index/shipping-capacity', component: ShippingCapacity },
  { path: '/logistic-tools/sea-index/blank-sailings', component: BlankSailings, exact: true },
  { path: '/logistic-tools/sea-index/schedule-reliability', component: ScheduleReliability },
  { path: '/logistic-tools/sea-index/bunkering', component: Bunkering },
  { path: '/logistic-tools/sea-index/ship-price-index', component: ShipPriceIndex },

  { path: '/logistic-tools/sea-index/ocean-teu', component: GlobalOceanTeu },
  { path: '/logistic-tools/sea-index/mupv', component: MUPV, exact: true },

  {
    path: '/logistic-tools/congestion-in-logistics/west-usa-terminal-congestion/',
    component: WestUsaTerminalCongestion,
  },
  {
    path: '/logistic-tools/congestion-in-logistics/globalchokepoint',
    component: GlobalChokePointMonitor,
    exact: true,
  },
  {
    path: '/logistic-tools/congestion-in-logistics/us-intermodal-terminal-congested',
    component: UsaTerminalCongested,
  },
  {
    path: '/logistic-tools/congestion-in-logistics/ship-tracking',
    component: ShipTracking,
    exact: true,
  },
  { path: '/logistic-tools/congestion-in-logistics/usa-truck-congestion', component: UsaTruckCongestion },
  { path: '/logistic-tools/congestion-in-logistics/world-weather-map', component: WorldWeatherMap, exact: true },
  { path: '/logistic-tools/air-inland-index/air-rate-index', component: AirRateIndex },
  { path: '/logistic-tools/air-inland-index/fssc', component: FSSC },
  { path: '/logistic-tools/air-inland-index/jfpm', component: JFPM, exact: true },
  { path: '/logistic-tools/air-inland-index/iata', component: IaTa },
  { path: '/logistic-tools/air-inland-index/iatap', component: IaTaP },
  { path: '/logistic-tools/air-inland-index/oagc', component: OagGlobalCapacity },
  { path: '/logistic-tools/air-inland-index/aapa', component: AAPA },
  { path: '/logistic-tools/air-inland-index/usa-truck-rate-index', component: UsaTruckRateIndex },
  {
    path: '/logistic-tools/air-inland-index/usa-gasoline-diesel',
    component: UsaGasolineDiesel,
  },
  {
    path: '/logistic-tools/air-inland-index/isr',
    component: UsaIntermodalSpotRate,
    exact: true,
  },
  { path: '/logistic-tools/other-index/eu-eua/', component: EuEua, exact: true },
  { path: '/logistic-tools/other-index/pmi-index', component: PmiIndex },
  { path: '/logistic-tools/other-index/lmi-index', component: LMI },
  { path: '/logistic-tools/other-index/mtsr', component: Mtsr },
  { path: '/logistic-tools/other-index/gscpi', component: Gscpi },
  { path: '/logistic-tools/other-index/blackrock', component: BlackRock, exact: true },
  { path: '/logistic-tools/other-index/fedgpr', component: Gpr },
  { path: '/logistic-tools/raw-materials-index/oil-index', component: OilIndex, exact: true },
  { path: '/logistic-tools/raw-materials-index/global-mineral-prices', component: GlobalMineralPrices },
  {
    path: '/logistic-tools/raw-materials-index/fao/overview',
    component: FaoOverview,
    exact: true,
  },
  {
    path: '/logistic-tools/raw-materials-index/fao/price',
    component: FaoPrice,
    exact: true,
  },
  {
    path: '/logistic-tools/raw-materials-index/fao/grain',
    component: FaoGrain,
    exact: true,
  },
  // { path: '/logistic-tools/issue-monitoring/monitoring', component: IssueMonitoring },
  { path: '/logistic-tools/business-support/national-holiday', component: NationalHoliday, exact: true },
  { path: '/logistic-tools/business-support/inland-tariff-rate', component: InlandTariffRate, exact: true },
  { path: '/logistic-tools/business-support/customs-exchange-rate', component: CustomExchangeRate, exact: true },
  { path: '/logistic-tools/business-support/vehicle-spec', component: VehicleSpecMain, exact: true },
  { path: '/logistic-tools/business-support/logistics-facillty-list', component: FacilltyList, exact: true },
  // { path: '/logistic-tools/business-support/country-exchange-rate', component: WordExchangeRate },
  { path: '/logistic-tools/business-support/import-cargo-clearance', component: ImportCargoClearance, exact: true },
  { path: '/logistic-tools/calculator/cbm-calculator', component: CbmCalculator, exact: true },
  { path: '/logistic-tools/calculator/duty-calculator', component: DutyCalculator, exact: true },
];

const MainRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/auth-callback" component={MemeberAuthCallback} />
        <Route path="/health/check" component={HealthCheck} />
        <Route path="/reset-password" component={ResetPassword} />
        <WebMainLayout>
          <Switch>
            <Suspense fallback={<></>}>
              <Route exact path="/" component={Home} />

              {(process.env.REACT_APP_PUBLIC_URL === 'https://dev-larchive.simplogis.com' ||
                process.env.REACT_APP_PUBLIC_URL === 'http://localhost:8081') && (
                <>
                  <Route path="/riskmonitor" component={RiskMonitor} />
                  <Route path="/freight-condition" component={FreightConditionMonitorV1} />
                </>
              )}

              <Route path="/portmonitoring" component={PortCongestionV3} />

              <Route exact path="/global-supply-chain" component={GlobalSupplyChain} />

              {LOGISTIC_ROUTE_LIST.map((route, idx) => (
                <Route
                  exact={route?.exact}
                  key={`${route.path}_${idx}`}
                  path={route.path}
                  render={props => {
                    // if (!isLoggedIn) {
                    //   if (props.location.pathname !== '/logistic-tools/main') {
                    //     window.location.replace('/logistic-tools/main');
                    //     return;
                    //   }
                    // }

                    return (
                      <LogisticToolsContainerAdaptiveLayout {...props}>
                        <route.component />
                      </LogisticToolsContainerAdaptiveLayout>
                    );
                  }}
                />
              ))}

              <Route path="/terms/term" component={Term} />

              <Route path="/terms/privacy" component={Privacy} />

              <Route path="/partnership" component={Partnership} />

              <Route path="/ad-inquiry" component={Adinquiry} />

              <Route path="/plan" component={Plan} />

              <Route path="/faq" component={Faq} />

              <Route exact path="/company-ad" component={CompanyAd} />

              <Route path="/company-ad/:id" component={CompanyAdDetail} />

              <Route path="/community/notice" component={Notice} />

              <Route path="/community/news-center" component={NewsCenter} />

              <Route path="/community/reference-room" component={ReferenceRoom} />

              {/* <Route path="/ranking/fame" component={Fame} /> */}

              {/* <Route path="/ranking/knowledge" component={Knowledge} /> */}

              <Route exact path="/warehouse_quote" component={CargoEstimates} />

              <Route
                exact
                path={'/mypage'}
                // render={() => (
                //   <MypageMainLayout>
                //     <MypageMain />
                //   </MypageMainLayout>
                // )}
              >
                {
                  <MypageMainLayout>
                    <MypageMain />
                  </MypageMainLayout>
                }
              </Route>
              <Route
                exact
                path={'/mypage/default/:id'}
                render={() => (
                  <MypageMainLayout>
                    <MypageDefaultConfig />
                  </MypageMainLayout>
                )}
              />
              <Route
                exact
                path={'/mypage/payment/:id'}
                render={() => (
                  <MypageMainLayout>
                    <MypagePayment />
                  </MypageMainLayout>
                )}
              ></Route>

              <Route
                exact
                path={'/mypage/teamplan/:id'}
                render={() => (
                  <MypageMainLayout>
                    <MypageTeamPlan />
                  </MypageMainLayout>
                )}
              ></Route>

              <Route
                exact
                path={'/mypage/company/about'}
                render={() => (
                  <MypageMainLayout>
                    <MypageCompanyAbout />
                  </MypageMainLayout>
                )}
              />

              <Route
                exact
                path={'/mypage/message'}
                render={() => (
                  <MypageMainLayout>
                    <MypageMessage />
                  </MypageMainLayout>
                )}
              />

              <Route exact path={'/payment/cancel'}>
                <MypagePaymentCancel />
              </Route>
              <Route exact path={'/payment/refund'}>
                <MypagePaymentRefund />
              </Route>
            </Suspense>

            <Route path="*" component={NotFoundPage} />
          </Switch>
        </WebMainLayout>
      </Switch>
    </Router>
  );
};

export default MainRouter;
