import { useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { DateTime } from 'luxon';

import mainAuth from 'auth';

import sweetAlert from 'prev-components/Alert/sweetAlert';

import { USE_LARCHIVE_FROM_OTHER_SERVICE_FOR_SNS } from 'graphql/Login/useLarchiveFromOtherServiceForSns';
import {
  Mut_Use_Larchive_From_Other_Service_For_Sns,
  Mut_Use_Larchive_From_Other_Service_For_SnsVariables,
} from '__apollo__generated__/Mut_Use_Larchive_From_Other_Service_For_Sns';
import { Sns } from '__apollo__generated__/globalTypes';
import { ACTIVE_DORMANT_ACCOUNT } from 'graphql/Login/activateDormantAccount';
import {
  Mut_Active_Dormant_Account,
  Mut_Active_Dormant_AccountVariables,
} from '__apollo__generated__/Mut_Active_Dormant_Account';
import { LOGIN_CREATE_SIMPLOGIS_USER } from 'graphql/Login/loginOldAccountAndCreateSimplogisUser';
import {
  Mut_Login_Old_Account_And_Create_Simplogis_User,
  Mut_Login_Old_Account_And_Create_Simplogis_UserVariables,
} from '__apollo__generated__/Mut_Login_Old_Account_And_Create_Simplogis_User';
import { Mut_Get_Larchive_Token } from '__apollo__generated__/Mut_Get_Larchive_Token';
import { GET_LARCHIVE_TOKEN } from 'graphql/Login';
import { gtagEvent } from 'lib/gtag';
import LazyImage from 'prev-components/Common/LazyImage';
import { DefaultColorLogo } from 'assets/image';
import Spinner from 'prev-components/Common/LoadingIndicator/Spinner';

const MemeberAuthCallback = () => {
  const history = useHistory();

  const [getLarchiveToken] = useMutation<Mut_Get_Larchive_Token>(GET_LARCHIVE_TOKEN, {
    fetchPolicy: 'no-cache',

    onError(error) {
      if (error?.message?.includes('Forbidden')) {
        sweetAlert({
          icon: 'warning',
          title: error?.message || '잘못된 접근입니다.',
          description: (
            <div>
              <div>help@simplogis.com으로 문의 바랍니다.</div>
              <div>발생경로 : 간편 로그인 onError</div>
              <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
            </div>
          ),
        }).then(() => {
          const cookies = new Cookies();
          ['token', 'email', 'error', 'sns', 'sns-access-token'].forEach(cookie => {
            cookies.remove(cookie, {
              path: '/',
            });
          });
          return window.close();
        });
      }
    },
  });

  // * 휴면 활성화 api
  const [activateDormantAccount] = useMutation<Mut_Active_Dormant_Account, Mut_Active_Dormant_AccountVariables>(
    ACTIVE_DORMANT_ACCOUNT,
  );
  // * profile check api
  const [createLarchiveProfileForSns] = useMutation<
    Mut_Use_Larchive_From_Other_Service_For_Sns,
    Mut_Use_Larchive_From_Other_Service_For_SnsVariables
  >(USE_LARCHIVE_FROM_OTHER_SERVICE_FOR_SNS);
  // * 구유저 통합 생성
  const [createIntegrationAccount] = useMutation<
    Mut_Login_Old_Account_And_Create_Simplogis_User,
    Mut_Login_Old_Account_And_Create_Simplogis_UserVariables
  >(LOGIN_CREATE_SIMPLOGIS_USER);

  // * broad cast channel open
  const userChannel = useMemo(() => {
    const channel = new BroadcastChannel('userState');
    return channel;
  }, []);

  useEffect(() => {
    const allCookieDelete = (cookieList: string[]) => {
      cookieList.forEach(cookie => {
        cookies.remove(cookie, {
          path: '/',
        });
      });
    };

    const cookies = new Cookies();
    // * kakao sns access token
    const snsAccessToken = cookies.get('sns-access-token');
    // * custom token
    const resToken = cookies.get('token');
    // * email
    const resEmail = cookies.get('email');
    // * sns type
    const resSns = cookies.get('sns');
    // * error message
    const resError = cookies.get('error');

    const cookieList = ['token', 'email', 'error', 'sns', 'sns-access-token'];
    // * login process function
    const loginProcess = async (customToken: string) => {
      try {
        const { data } = await createLarchiveProfileForSns({
          variables: {
            input: {
              email: resEmail,
              whichSns: `${resSns?.trim()?.charAt(0)?.toUpperCase()}${resSns?.trim()?.slice(1)?.toLowerCase()}` as Sns,
            },
          },
        });
        const { ok, error } = data?.useLarchiveFromOtherServiceForSns!;
        if (!ok || error?.message) {
          console.log(error, 'failed to check profile');
          return sweetAlert({
            icon: 'info',
            title: error?.message || '로그인에 실패했습니다.',
            description: (
              <div>
                <div>help@simplogis.com으로 문의 바랍니다.</div>
                <div>발생경로 : 간편 로그인 라카이브 프로필 생성</div>
                <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
                <div></div>
              </div>
            ),
            showDeny: false,
          }).then(async () => {
            allCookieDelete(cookieList);
            return window.close();
          });
        }

        const token = await mainAuth().authStateManage.getLoginAuth(customToken);

        cookies.set('firebase-id-token', token, {
          maxAge: 31557600,
          sameSite: 'lax',
          path: '/',
        });

        const { data: larchiveTokenData } = await getLarchiveToken({
          context: {
            headers: {
              'firebase-id-token': token,
            },
          },
        });

        if (
          !larchiveTokenData ||
          !larchiveTokenData?.getLarchiveToken?.ok ||
          larchiveTokenData?.getLarchiveToken?.error ||
          !larchiveTokenData?.getLarchiveToken?.larchiveToken
        ) {
          sweetAlert({
            icon: 'warning',
            title: larchiveTokenData?.getLarchiveToken?.error || '로그인에 실패했습니다.',
            description: (
              <div>
                <div>help@simplogis.com으로 문의 바랍니다.</div>
                <div>발생경로 : 간편 로그인 토큰생성</div>
                <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
              </div>
            ),
            showDeny: false,
          }).then(async () => {
            cookies.remove('firebase-id-token', {
              path: '/',
            });
            allCookieDelete(cookieList);
            return window.close();
          });

          return;
        }

        const larchvietoken = larchiveTokenData?.getLarchiveToken?.larchiveToken;

        cookies.set('larchive-token', larchvietoken, {
          maxAge: 31557600,
          sameSite: 'lax',
          path: '/',
        });

        if (resSns?.trim()?.toLowerCase() === 'kakao' && snsAccessToken) {
          window.Kakao.Auth.setAccessToken(snsAccessToken);
        }

        allCookieDelete(cookieList);

        userChannel.postMessage({
          payload: {
            type: 'login-complete',
          },
        });

        gtagEvent({
          action: 'Login',
        });

        return window.close();
      } catch (err) {
        console.log(err, 'failed to sns login');
        sweetAlert({
          icon: 'warning',
          title: (err as unknown as Error)?.message || '로그인에 실패했습니다. Except Error',
          description: (
            <div>
              <div>help@simplogis.com으로 문의 바랍니다.</div>
              <div>발생경로 : 간편 로그인 process ExceptError </div>
              <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
            </div>
          ),
          showDeny: false,
        }).then(async () => {
          cookies.remove('firebase-id-token', {
            path: '/',
          });
          allCookieDelete(cookieList);
          return window.close();
        });
      }
    };

    try {
      (async () => {
        // error코드가 왔을경우.
        // if (resError || !resEmail || !resSns || !resToken) {
        if (resError) {
          // error decode
          const deCodeError = decodeURI(resError);
          console.log('failed to sns login cause no token response');
          console.log(deCodeError, 'decode error');

          // if ((deCodeError === 'undefined' || deCodeError === undefined || !deCodeError) && resToken) {
          //   return loginProcess(resToken);
          // }

          if (deCodeError.includes('휴면')) {
            return sweetAlert({
              icon: 'info',
              title: deCodeError,
              description: (
                <div>
                  <div>계정을 활성화하시겠습니까?</div>
                  <div>동의시 라카이브 프로필이 자동으로 생성됩니다.</div>
                  <div>미동의시 라카이브 서비스 이용이 불가합니다.</div>
                </div>
              ),
              showDeny: true,
              confirmButtonText: '동의',
            }).then(async res => {
              if (res.isConfirmed) {
                const { data: activateAccountData } = await activateDormantAccount({
                  variables: {
                    input: {
                      email: resEmail,
                      isSns: true,
                    },
                  },
                });
                if (activateAccountData?.activateDormantAccount) {
                  const {
                    ok: activateOk,
                    error: activateError,
                    customToken: activateCustomToken,
                  } = activateAccountData?.activateDormantAccount;
                  if (!activateOk || activateError || !activateCustomToken) {
                    console.log(activateError, 'failed to activate dormant account');
                    return sweetAlert({
                      icon: 'info',
                      title: activateError?.message || '계정 활성화에 실패했습니다',
                      description: (
                        <div>
                          <div>help@simplogis.com으로 문의 바랍니다.</div>
                          <div>발생경로 : 간편 로그인 휴면 활성화</div>
                          <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
                        </div>
                      ),
                    }).then(async () => {
                      allCookieDelete(cookieList);
                      window.close();
                      return;
                    });
                  }
                  // * 로그인 프로세스 진행
                  return loginProcess(activateCustomToken);
                }
              }
              allCookieDelete(cookieList);
              window.close();
              return;
            });
            // * 구유저인 경우 무조건 로그인은 진행 함.
          } else if (deCodeError.includes('디비')) {
            return sweetAlert({
              icon: 'info',
              title: '심플로지스 통합계정이 아닙니다',
              description: (
                <div>
                  <div>통합계정으로 전환에 동의하시겠습니까?</div>
                  <div>동의하시면 심플로지스의 다른 서비스도 함께 이용가능합니다</div>
                </div>
              ),
              confirmButtonText: '동의',
              showDeny: true,
            }).then(async res => {
              if (res.isConfirmed) {
                try {
                  const { data } = await createIntegrationAccount({
                    variables: {
                      input: {
                        email: resEmail,
                        whichSns: `${resSns.trim().charAt(0).toUpperCase()}${resSns
                          .trim()
                          .slice(1)
                          .toLowerCase()}` as Sns,
                      },
                    },
                  });
                  if (
                    !data?.loginOldAccountAndCreateSimplogisUser.ok ||
                    data?.loginOldAccountAndCreateSimplogisUser.error
                  ) {
                    return sweetAlert({
                      icon: 'error',
                      title:
                        data?.loginOldAccountAndCreateSimplogisUser.error?.message || '통합계정 생성에 실패했습니다.',
                      description: (
                        <div>
                          <div>help@simplogis.com으로 문의 바랍니다.</div>
                          <div>발생경로 : 간편 로그인 통합계정 생성</div>
                          <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
                        </div>
                      ),
                    }).then(async () => {
                      allCookieDelete(cookieList);
                      return window.close();
                    });
                  }
                  return loginProcess(resToken);
                } catch (err) {
                  console.log(err);
                  return sweetAlert({
                    icon: 'error',
                    title: '통합계정 생성에 실패했습니다.',
                    description: (
                      <div>
                        <div>help@simplogis.com으로 문의 바랍니다.</div>
                        <div>발생경로 : 간편 로그인 통합 동의</div>
                        <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
                      </div>
                    ),
                  }).then(async () => {
                    allCookieDelete(cookieList);
                    return window.close();
                  });
                }
              }
              return loginProcess(resToken);
            });
          } else if (
            typeof deCodeError === 'string' &&
            deCodeError?.trim() !== '' &&
            deCodeError !== 'undefined' &&
            deCodeError !== undefined &&
            deCodeError !== null
          ) {
            return sweetAlert({
              icon: 'error',
              title: deCodeError || '로그인에 실패했습니다',
              description: (
                <div>
                  <div>help@simplogis.com으로 문의 바랍니다.</div>
                  <div>발생경로 : 간편 로그인 Decode Error</div>
                  <div>
                    email: {resEmail} | sns: {resSns} | token: {resToken?.silice(0, 3) || ''}
                  </div>
                  <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
                </div>
              ),
            }).then(async () => {
              allCookieDelete(cookieList);
              return window.close();
            });
          }

          // if (resToken) {
          //   return loginProcess(resToken);
          // }

          // console.log(deCodeError, 'down');
          // return sweetAlert({
          //   icon: 'warning',
          //   title: deCodeError || '로그인에 실패했습니다. ',
          //   description: (
          //     <div>
          //       <div>help@simplogis.com으로 문의 바랍니다.</div>
          //       <div>발생경로 : 간편 로그인 최종 ExceptError 1</div>
          //       <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
          //     </div>
          //   ),
          //   showDeny: false,
          // }).then(async () => {
          //   allCookieDelete(cookieList);
          //   return window.close();
          // });
        }

        if (!resEmail || resEmail?.trim() === '') {
          return sweetAlert({
            icon: 'warning',
            title: 'email이 확인되지 않았습니다.',
            description: (
              <div>
                <div>help@simplogis.com으로 문의 바랍니다.</div>
                <div>발생경로 : 간편 로그인 최종 ExceptError 1</div>
                <div>
                  email: {resEmail} | sns: {resSns} | token: {resToken?.silice(0, 3) || ''}
                </div>
                <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
              </div>
            ),
            showDeny: false,
          }).then(async () => {
            allCookieDelete(cookieList);
            return window.close();
          });
        }

        if (!resSns || resSns?.trim() === '') {
          return sweetAlert({
            icon: 'warning',
            title: 'Sns정보가 확인되지 않았습니다.',
            description: (
              <div>
                <div>help@simplogis.com으로 문의 바랍니다.</div>
                <div>발생경로 : 간편 로그인 최종 ExceptError 1</div>
                <div>
                  email: {resEmail} | sns: {resSns} | token: {resToken?.silice(0, 3) || ''}
                </div>
                <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
              </div>
            ),
            showDeny: false,
          }).then(async () => {
            allCookieDelete(cookieList);
            return window.close();
          });
        }

        if (!resToken || resToken?.trim() === '') {
          return sweetAlert({
            icon: 'warning',
            title: 'token 정보가 확인되지 않았습니다.',
            description: (
              <div>
                <div>help@simplogis.com으로 문의 바랍니다.</div>
                <div>발생경로 : 간편 로그인 ExceptError </div>
                <div>
                  email: {resEmail} | sns: {resSns} | token: {resToken?.silice(0, 3) || ''}
                </div>
                <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
              </div>
            ),
            showDeny: false,
          }).then(async () => {
            allCookieDelete(cookieList);
            return window.close();
          });
        }

        // * 정상 로그인인 경우 함수 실행
        return loginProcess(resToken);
      })();
    } catch (err) {
      console.log(err, 'failed to sns login');
      sweetAlert({
        icon: 'warning',
        title: (err as unknown as Error)?.message || '로그인에 실패했습니다. Except Error',
        description: (
          <div>
            <div>help@simplogis.com으로 문의 바랍니다.</div>
            <div>발생경로 : 간편 로그인 시도 Uncaught Except Error</div>
            <div>발생시간 : {DateTime.fromMillis(Date.now()).toFormat('yyyy-MM-dd HH:mm:ss')}</div>
          </div>
        ),
        showDeny: false,
      }).then(async () => {
        allCookieDelete(cookieList);
        return window.close();
      });
    }
  }, [
    history,
    userChannel,
    createLarchiveProfileForSns,
    activateDormantAccount,
    createIntegrationAccount,
    getLarchiveToken,
  ]);
  return (
    <section className="flex flex-col items-center justify-start w-screen h-screen pt-[300px] relative">
      <Spinner isAbsolute={true} />
      <div className="-ml-5 ">
        <LazyImage src={DefaultColorLogo} alt={'larchive-logo'} height={80} />
      </div>
      <h1 className="text-[24px] text-slate-600 font-bold mt-10">간편 로그인 진행 중입니다.</h1>
      <div className="mt-10">
        <div className="flex items-center flex-col gap-3 text-[16px] text-slate-500 font-semibold">
          <div>잠시만 기다려주세요.</div>
          <div>
            만약 정상적으로 로그인이 진행되지 않는다면
            <span className="underline text-main-color underline-offset-4"> help@simplogis.com</span> 으로 문의
            바랍니다.
          </div>
        </div>
      </div>
    </section>
  );
};

export default MemeberAuthCallback;
