import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';

import { headerLogo } from 'assets/image';
import LazyImage from 'prev-components/Common/LazyImage';
import useMemoCookies from 'hooks/Common/useMemoCookies';
import usePageHistory from 'hooks/Common/usePageHistory';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from 'lib/apollo/apollo';
import { showLogin } from 'store/Member';

export function UpdateNoticePop({
  close,
  cookieName,
}: {
  close: Dispatch<SetStateAction<boolean>>;
  cookieName: string;
}) {
  const memoCookies = useMemoCookies();

  const [currentCheckDay, setCurrentCheckDay] = useState<number | null>(null);

  const { history } = usePageHistory();

  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const handleCloseToday = () => {
    if (!currentCheckDay) {
      close(false);
      return;
    }

    memoCookies.set(cookieName, cookieName, {
      path: '/',
      expires: DateTime.now().plus({ days: 1 }).toJSDate(),
      maxAge: 24 * 60 * 60 * currentCheckDay,
      sameSite: 'lax',
    });
    close(false);
  };

  return (
    <div className="absolute top-[50vh] -translate-y-1/2 left-1/2 -translate-x-1/2 z-[55] bg-white shadow-mypage">
      <div className=" bg-release_paymentpop-bg-black  bg-cover text-white w-[850px] h-[556px] flex  ">
        <div className="w-full">
          <div className="absolute top-[84px] left-[70px]">
            <LazyImage src={headerLogo} width={216} alt="larchive-logo" />
          </div>
          <div className="mt-[188px] mx-[30px] w-[52px] border-t-[4px] border-solid border-white"></div>
          <div className="text-[20px] mx-[30px] mt-[12px]">
            <div className="leading-[50px]">[업데이트 이력]</div>
          </div>
          <div className="mx-[30px] text-[16px] font-light mt-[12px] leading-[24px]">
            <div>
              1. 데이터 서비스:{' '}
              <em
                className="font-bold cursor-pointer text-baseColor"
                onClick={() => {
                  if (!isLoggedIn) {
                    showLogin(true);
                    return;
                  }
                  history.push('/logistic-tools/sea-index/mupv?routes=7777929&category=bi76_mupv_01');
                }}
              >
                '미주 항만 컨 물동량'
              </em>
              은 컨테이너 수출/수입 물동량(TEU)데이터를 집계해서 제공
            </div>
            <div>
              2. 분석서비스:
              <em
                className="font-bold cursor-pointer text-baseColor"
                onClick={() => {
                  if (!isLoggedIn) {
                    showLogin(true);
                    return;
                  }
                  history.push('/logistic-tools/sea-index/container-rate-index/scfi');
                }}
              >
                '라카이브 GPT'
              </em>{' '}
              는 컨테이너 운임지수의 기간별 비교분석
              <div className="pl-3">
                (전주대비 변화율, 전월대비 트렌드, 그리고 전년동월과의 차이 등)에 대한 코멘트 제공
              </div>
            </div>
            <div>
              3. 분석서비스: 기존 항만혼잡도를{' '}
              <em
                className="font-bold cursor-pointer text-baseColor"
                onClick={() => {
                  if (!isLoggedIn) {
                    showLogin(true);
                    return;
                  }
                  history.push('/portmonitoring');
                }}
              >
                '항만모니터링'
              </em>{' '}
              으로 변경하고, 지연일수와 항만별 물동량 데이터를 추가제공
            </div>
          </div>
          <div className="text-[20px] mx-[30px] mt-[12px]">
            <div className="leading-[50px]">[대시보드 디자인 리뉴얼 예정]</div>
          </div>
          <div className="mx-[30px] text-[16px] font-light mt-[12px] leading-[24px]">
            <div>더 나은 사용자 경험을 제공하기 위해 대시보드 디자인 리뉴얼 작업을 진행 중에 있으며,</div>
            <div>빠른 시일 내에 리뉴얼된 대시보드를 만나보실 수 있도록 최선을 다하겠습니다.</div>
          </div>
        </div>
        {/* <div className="pt-[40px]  h-full flex flex-col justify-between pb-[70px]">
          <div className="flex flex-col items-end justify-center pr-[80px]">
            <div className="text-[16px] font-light">단, 하나의 글로벌 공급망 인텔리전스</div>
            <GothamDiv className="text-[#78ECB6] text-[18px] leading-[40px]">See everything, Do anything</GothamDiv>
          </div>
          <div className="flex flex-col font-bold gap-4  place-self-end mr-[70px] w-[340px] h-[270px] border-[#fff] border-[4px] border-solid px-[20px] py-[21px]">
            <div className="text-[24px] leading-[30px]">안내사항</div>
            <div className="text-[16px] flex items-center gap-2 ">
              <span className='before:content-["•"] before:mr-1'>대상</span>
              <span className="font-thin text-[#828080] text-[13px]">|</span>
              <span>라카이브 사용중인 모든 고객</span>
            </div>
            <div className="text-[16px] flex items-center gap-2 whitespace-nowrap ">
              <span className='before:content-["•"] before:mr-1'>기간</span>
              <span className="font-thin text-[#828080] text-[13px]">|</span>
              <span>2024년 10월 28일~</span>
            </div>
            <div className="text-[16px] flex items-center gap-2">
              <span className='before:content-["•"] before:mr-1'>내용</span>
              <span className="font-thin text-[#828080] text-[13px]">|</span>
              <span>팀 플랜 결제 기능 추가</span>
            </div>
            <div className="text-[16px] flex items-center gap-2 ">
              <span className='before:content-["•"] before:mr-1'>문의</span>
              <span className="font-thin text-[#828080] text-[13px]">|</span>
              <span className="underline underline-offset-2">help@simplogis.com</span>
            </div>
            <div
              onClick={handleGoPlan}
              className="w-[260px] h-[40px] bg-white flex items-center justify-between px-5 text-black text-[14px] cursor-pointer mt-2 place-self-center"
            >
              <span>프라이싱 페이지 가기</span>
              <LineArrow />
            </div>
          </div> */}
        {/* </div> */}
      </div>
      <div className="h-[44px] border-[1px] border-solid border-white text-[14px] text-[#555] flex justify-between items-center px-[20px]">
        <div className="flex items-center cursor-pointer ">
          <input
            id="default-checkbox"
            type="checkbox"
            value="1"
            readOnly
            checked={currentCheckDay === 1}
            onClick={e => {
              if (e.currentTarget.checked) {
                setCurrentCheckDay(+e.currentTarget.value);
              } else {
                setCurrentCheckDay(null);
              }
            }}
            // onChange={() => {
            //   setCurre
            // }}
            className="w-4 h-4 text-white accent-baseColor focus:ring-black"
          />
          <label
            htmlFor="default-checkbox"
            className="text-sm font-medium text-gray-900 cursor-pointer ms-2 dark:text-gray-300"
          >
            하루동안 보지 않기
          </label>

          <input
            id="sevenday-checkbox"
            type="checkbox"
            value="99999999"
            readOnly
            checked={currentCheckDay === 99999999}
            onClick={e => {
              if (e.currentTarget.checked) {
                setCurrentCheckDay(+e.currentTarget.value);
              } else {
                setCurrentCheckDay(null);
              }
            }}
            // onChange={_handleCheckNoShow}
            className="w-4 h-4 ml-3 text-white accent-baseColor focus:ring-black"
          />
          <label
            htmlFor="sevenday-checkbox"
            className="text-sm font-medium text-gray-900 cursor-pointer ms-2 dark:text-gray-300"
          >
            다시 보지 않기
          </label>
        </div>
        <div className="cursor-pointer" onClick={handleCloseToday}>
          닫기
        </div>
      </div>
    </div>
  );
}

const GothamDiv = styled.div`
  font-family: 'GothamMedium';
`;
