import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import { client } from 'lib/apollo/apollo';
import { ThemeProvider } from 'styled-components';
import { RecoilRoot } from 'recoil';
import ReactGA from 'react-ga4';
import './styles/tailwind.css';

import App from './App';
import GlobalStyle from 'styles/GlobalStyle';
import theme from 'styles/theme/theme';
import LocalErrorBoundary from 'prev-components/Common/ErrorBoundary/LocalErrorBoundary';

window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`);

const root = createRoot(document.getElementById('root')!);

root.render(
  <ApolloProvider client={client}>
    <RecoilRoot>
      <LocalErrorBoundary>
        <ThemeProvider theme={theme}>
          <GlobalStyle />

          <App />
        </ThemeProvider>
      </LocalErrorBoundary>
    </RecoilRoot>
  </ApolloProvider>,
);
